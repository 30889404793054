//import "@stimulus/polyfills"
import {Application} from "stimulus"
import {definitionsFromContext} from "stimulus/webpack-helpers"
import Flatpickr from "stimulus-flatpickr";

const application = Application.start()
const context = require.context("date_select", true, /-controller\.js$/)
application.load(definitionsFromContext(context))

//register Flatpickr as a stimulus controller
application.register("flatpickr", Flatpickr);
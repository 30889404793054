import {Controller} from "stimulus";
import flatpickr from "flatpickr";

export default class extends Controller {
  static targets = ["input"];

  initialize() {
    let format = this.data.get('format') || 'Z';
    console.log(this.data.get('format'));
    this.fp = flatpickr(this.input, {
      dateFormat:format,
      allowInput:true,
      onChange: (selectedDates, dateStr, instance) => {
        this.input.value = dateStr;
      }
    });
  }

  get input() {
    if (this.hasInputTarget) {
      return this.inputTarget;
    }
    return this.element;
  }

  openCalendar() {
    this.fp.open();
  }
}
import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ['input', 'month', 'day', 'year'];

  setDate(event) {
    let value = event.currentTarget.value.trim();
    if (!this.hasPartialDate) {
      this.inputTarget.value = this.date;
    }
    this.setRequired(this.hasPartialDate);
  }

  setRequired(required) {
    this.monthTarget.required = required;
    this.dayTarget.required = required;
    this.yearTarget.required = required;
  }

  allEmpty() {
    return (this.month === '' && this.day === '' && this.year === '')
  }

  checkMonth() {
    if (parseInt(this.month) > 12) {
      alert('Month must between 1 and 12');
      this.monthTarget.value = '';
      this.monthTarget.focus();
    }
    if (this.month.length === 2) {
      this.dayTarget.focus();
    }
  }

  checkDay() {
    if (parseInt(this.day) > 31) {
      alert('Please select a valid day');
      this.dayTarget.value = '';
      this.dayTarget.focus();

    }
    if (this.day.length === 2) {
      this.yearTarget.focus();
    }
  }

  checkYear() {
    if (this.year.length === 4) {
      this.yearTarget.blur();
    }
  }

  get hasPartialDate() {
    if (this.allEmpty()) {
      return false;
    }
    return (this.month === '' || this.day === '' || this.year === '');
  }

  get date() {
    return this.paddedMonth + '/' + this.paddedDay + '/' + this.year;
  }

  get month() {
    return this.monthTarget.value.trim();
  }

  get paddedMonth() {
    if (this.month.length === 1) {
      return '0' + this.month;
    }
    return this.month;
  }

  get day() {
    return this.dayTarget.value.trim();
  }

  get paddedDay() {
    if (this.day.length === 1) {
      return '0' + this.day;
    }
    return this.day;
  }

  get year() {
    return this.yearTarget.value.trim();
  }

}
